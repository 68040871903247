.text-chain-gradient {
    background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #8b5cf6 75%, #8b5cf6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


.background-chain-gradient {
    background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #8b5cf6 75%, #8b5cf6);
    transition: background 0.3s ease-in-out; /* Add a smooth transition */
}

/* .background-chain-gradient-light {
    background: linear-gradient(45deg, #fce588, #fce588 25%, #b793f9 75%, #b793f9);
    transition: background 0.3s ease-in-out;
} */


.background-chain-gradient:hover {
    background: linear-gradient(45deg, #8b5cf6, #8b5cf6 25%, #fbbf24 75%, #fbbf24);
}


@keyframes shine {
    0% {
      background-position: -150% 0;
    }
    50% {
      background-position: 150% 0;
    }
    100% {
      background-position: -150% 0;
    }
  }


.text-shine-gradient {
    background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #8b5cf6 75%, #8b5cf6, #fbbf24);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 5s linear infinite;
}

@keyframes burn {
  0% {
    background-position: -150% 0;
  }
  50% {
    background-position: 150% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.text-burn-gradient {
  background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #ff0000 50%, #8b5cf6 75%, #8b5cf6, #fbbf24);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5s linear infinite;
}

