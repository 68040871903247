/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes zoomIn {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.animate-zoom-in {
    animation: zoomIn 0.3s ease-out;
}

@keyframes gradientTransition {
    0% {
        background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #8b5cf6 75%, #8b5cf6);
        background-size: 200% 200%;
        background-position: 100% 0%;
    }
    100% {
        background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #8b5cf6 75%, #8b5cf6);
        background-size: 200% 200%;
        background-position: 0% 100%;
    }
}

.animate-button {
    animation: gradientTransition 0.2s ease 3;
}

.animate-button-inf {
    animation: gradientTransition 0.5s ease infinite;
}

ol {
    list-style-type: decimal;
    padding-left: 40px;
}

ol li {
    margin-bottom: 10px;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.notification {
    position: fixed;
    /* bottom: 90px;
    right: 20px; */
    bottom: 20px;
    right: 75px;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 4px;
    color: black;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    z-index: 1000;
    pointer-events: none;
  }

.notification.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}

.bg-chain-gradient {
    background: linear-gradient(45deg, #fbbf24, #fbbf24 25%, #8b5cf6 75%, #8b5cf6);
}

/* .carousel .slider-wrapper {
    padding-top: 50px;
}

.carousel .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.carousel .carousel-status {
    position: absolute;
    top: 0px;
    width: 100%;
    font-weight: bold;
    z-index: 2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px !important;
    height: 48px !important;
    font-size: 16px !important;
}

.carousel .slide iframe, .carousel .slide img{
    width: calc(100% - 20px) !important;
    margin: 0 10px !important;
} */