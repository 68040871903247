.bottom-nav-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #1a191f;
    padding: 1rem 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-decoration: none;
}

.nav-item.active {
    color: #ff4500; /* Highlight color for the active link */
}

.nav-item span {
    font-size: 0.75rem;
    margin-top: 0.25rem;
}
